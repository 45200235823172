<template>
  <div class="evaluate"
       style="width: 100%; height: 100%; overflow: auto">
    <van-nav-bar :title="$t('viewEvaluate.EvaTxt1')"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 :fixed="true" />
    <div class="fixedH"
         style="height: 1.22667rem"></div>
    <van-field v-if="is_master_iv"
               class="gzxz"
               :label="$t('viewEvaluate.EvaTxt2')"
               required
               :placeholder="$t('module.PleaseChoose')"
               :value="result"
               @click="showResult = true"
               right-icon="arrow"
               readonly>
    </van-field>
    <van-field v-if="!is_master_iv"
               class="gzxz"
               label="评价结果"
               required
               :placeholder="$t('module.PleaseChoose')"
               :value="result2"
               :disabled="is_f_post && !is_master_iv"
               @click="showResult2 = true"
               right-icon="arrow"
               readonly>
    </van-field>
    <div class="pjzfsBox"
         v-if="is_master_iv">
      <div class="left_pjzfsBox">
        <div>
          <div style="width:100%;font-size: 12px;"><strong style="color:red;font-size: 24px;">{{ totalScore }}</strong>分</div>
          <div style="width:100%;font-size: 12px;">评价总分数</div>
        </div>
      </div>
      <div class="right_pjzfsBox">
        <div class="flex1">
          <div class="flex1Item"
               @click="activeItem = 1"
               :class="{ 'activeItemCls':activeItem==1 }">
            <img style="width:40px;display: block;"
                 src="@/assets/img/ckpjxm_heise.png"
                 alt=""
                 v-if="activeItem != 1" />
            <img style="width:40px;display: block;"
                 src="@/assets/img/ckpjxm_lanse.png"
                 alt=""
                 v-if="activeItem == 1" />
            <p>查看评价项目</p>
          </div>
        </div>
        <div class="flex1">
          <div class="flex1Item"
               @click="activeItem = 2"
               :class="{ 'activeItemCls':activeItem==2 }">
            <img style="width:40px;display: block;"
                 src="@/assets/img/ckmsjl_heise.png"
                 alt=""
                 v-if="activeItem != 2" />
            <img style="width:40px;display: block;"
                 src="@/assets/img/ckmsjl_ls.png"
                 alt=""
                 v-if="activeItem == 2" />
            <p>查看面试记录</p>
          </div>
        </div>
        <div class="flex1">
          <div class="flex1Item"
               @click="activeItem = 3"
               :class="{ 'activeItemCls':activeItem==3 }">
            <img style="width:40px;display: block;"
                 src="@/assets/img/fmsgpj_heise.png"
                 alt=""
                 v-if="activeItem != 3" />
            <img style="width:40px;display: block;"
                 src="@/assets/img/fmsgpj_lanse.png"
                 alt=""
                 v-if="activeItem == 3" />
            <p>副面试官评价</p>
          </div>

        </div>
      </div>
      <div style="display: flex;align-items: center;">
        <van-icon @click="activeItem = 1"
                  v-if="activeItem == 0"
                  name="arrow-down" />
        <van-icon @click="activeItem = 0"
                  v-if="activeItem != 0"
                  name="arrow-up" />
      </div>
    </div>
    <!-- 面试记录 -->
    <van-collapse style="margin-bottom:12px;"
                  v-model="activeNames1"
                  class="msjlfshjdkhf"
                  v-if="RsIV.length > 0 && (activeItem == 2 || !is_master_iv) && (f_can_show_iv_list || is_master_iv)">
      <van-collapse-item :title="$t('viewInfo.InfoTxt26')"
                         name="5">
        <div>
          <div class="ivRecord"
               v-for="(it, index) in RsIV"
               :key="index">
            <div class="record_child">
              <van-row>
                <van-col span="8">
                  <span>{{ $t('viewInfo.InfoTxt27') }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.iv_times }}</span>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="8">
                  <span>{{ $t('viewInfo.InfoTxt28') }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.iv_type }}</span>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="8">
                  <span>{{ $t('viewInfo.InfoTxt29') }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.iv_date }}</span>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="8">
                  <span>{{ $t('viewInfo.InfoTxt30') }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.iv_pj }}</span>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="8">
                  <span>{{ $t('viewInfo.InfoTxt31') }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.iv_res }}</span>
                </van-col>
              </van-row>
              <van-row>
                <van-col span="8">
                  <span>{{ $t('viewInfo.InfoTxt32') }}</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.empname }}</span>
                </van-col>
              </van-row>

              <van-row v-if="it.f_empname1"
                       style="padding-top: 10px;border-top: 1px solid #dfdfdf;">
                <van-col span="8">
                  <span>副面试官1</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_empcode1 }} {{ it.f_empname1 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname1">
                <van-col span="8">
                  <span>评价结果</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_iv_res1?it.f_iv_res1:'暂无结果' }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname1">
                <van-col span="8">
                  <span>评价内容</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_pjmemo1?it.f_pjmemo1:'暂无评价' }}</span>
                </van-col>
              </van-row>

              <van-row v-if="it.f_empname2"
                       style="padding-top: 10px;border-top: 1px solid #dfdfdf;">
                <van-col span="8">
                  <span>副面试官2</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_empcode2 }} {{ it.f_empname2 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname2">
                <van-col span="8">
                  <span>评价结果</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_iv_res2?it.f_iv_res2:'暂无结果' }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname2">
                <van-col span="8">
                  <span>评价内容</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_pjmemo2?it.f_pjmemo2:'暂无评价' }}</span>
                </van-col>
              </van-row>

              <van-row v-if="it.f_empname3"
                       style="padding-top: 10px;border-top: 1px solid #dfdfdf;">
                <van-col span="8">
                  <span>副面试官3</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_empcode3 }} {{ it.f_empname3 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname3">
                <van-col span="8">
                  <span>评价结果</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_iv_res3?it.f_iv_res3:'暂无结果' }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname3">
                <van-col span="8">
                  <span>评价内容</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_pjmemo3?it.f_pjmemo3:'暂无评价' }}</span>
                </van-col>
              </van-row>

              <van-row v-if="it.f_empname4"
                       style="padding-top: 10px;border-top: 1px solid #dfdfdf;">
                <van-col span="8">
                  <span>副面试官4</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_empcode4 }} {{ it.f_empname4 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname4">
                <van-col span="8">
                  <span>评价结果</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_iv_res4?it.f_iv_res4:'暂无结果' }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname4">
                <van-col span="8">
                  <span>评价内容</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_pjmemo4?it.f_pjmemo4:'暂无评价' }}</span>
                </van-col>
              </van-row>

              <van-row v-if="it.f_empname5"
                       style="padding-top: 10px;border-top: 1px solid #dfdfdf;">
                <van-col span="8">
                  <span>副面试官5</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_empcode5 }} {{ it.f_empname5 }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname5">
                <van-col span="8">
                  <span>评价结果</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_iv_res5?it.f_iv_res5:'暂无结果' }}</span>
                </van-col>
              </van-row>
              <van-row v-if="it.f_empname5">
                <van-col span="8">
                  <span>评价内容</span>
                </van-col>
                <van-col span="15"
                         offset="1">
                  <span>{{ it.f_pjmemo5?it.f_pjmemo5:'暂无评价' }}</span>
                </van-col>
              </van-row>

              <div class="show_pj">
                <span style="font-size: 16px;color: red;">评价总分：{{ it.totalscore?it.totalscore:'未打分' }}</span>
                <van-button type="primary"
                            size="mini"
                            @click="showEvaItems(it.autoid)">{{ $t('viewInfo.InfoTxt33') }}</van-button>
              </div>
            </div>
          </div>
        </div>
      </van-collapse-item>
    </van-collapse>
    <!-- 面试结果 -->
    <img v-show="!!qresult || (is_f_post && !is_master_iv)"
         style="
        display: none;
        position: absolute;
        z-index: 9;
        top: 65px;
        left: 80px;
        width: 75px;
      "
         src="@/assets/img/toExamined-end.png"
         alt="" />
    <van-popup v-if="!qresult"
               v-model="showResult"
               position="bottom">
      <van-picker :title="$t('module.PleaseChoose')"
                  show-toolbar
                  :columns="resultColumns"
                  @confirm="onResultConfirm"
                  @cancel="showResult == false" />
    </van-popup>
    <van-field v-if="result == '面试淘汰'"
               class="gzxz"
               label="淘汰原因"
               required
               :placeholder="$t('module.PleaseChoose')"
               :value="iv_outreason"
               @click="showReason = true"
               right-icon="arrow"
               readonly>
    </van-field>
    <van-popup v-if="!is_master_iv"
               v-model="showResult2"
               position="bottom">
      <van-picker :title="$t('module.PleaseChoose')"
                  show-toolbar
                  :columns="resultPjColumns"
                  @confirm="onResultConfirm2"
                  @cancel="showResult2 = false" />
    </van-popup>
    <van-popup v-if="!qresult"
               v-model="showReason"
               position="bottom">
      <van-picker :title="$t('module.PleaseChoose')"
                  show-toolbar
                  :columns="ReasonColumns"
                  @confirm="onReasonConfirm"
                  @cancel="showReason == false" />
    </van-popup>
    <div style="margin-top:12px;"
         class="pjmxl"
         v-if="pjdata.length > 0 && is_master_iv && activeItem == 1">
      <div class="pjmxl_top">
        <div class="pjmxl_top_left">
          <span>{{ $t("viewEvaluate.EvaTxt3") }}</span>
        </div>
        <div class="pjmxl_top_right">
          <span>{{ $t("viewEvaluate.EvaTxt4") }}</span>
        </div>
      </div>
      <div class="pjmxl_body">
        <div class="pjmxl_top_list"
             v-for="(value, key) in pjdata"
             :key="key">
          <div class="fzclick"
               v-if="!!qresult"></div>
          <div class="flexboxbox">
            <div class="pjmxl_top_listLeft"
                 :class="
                (result == '面试通过' && value.tg_bt) ||
                (result == '面试淘汰' && value.tt_bt) ||
                (result == '待定' && value.dd_bt)
                  ? 'pjmxl_top_must'
                  : ''
              ">
              {{ value.optitle }}
            </div>
            <div v-if="value.opflagname == '单选' || value.opflagname == '多选'"
                 class="pjmxl_top_listRight pjmxl_top_list_check">
              <div class="pjmxl_top_list_check-center">
                <div class="pjmxl_top_list_check-top">
                  <label :for="'id_a_' + value.autoid + '_k_' + idx"
                         v-for="(itm, idx) in value.optarr"
                         :key="idx">
                    <input :readonly="!!qresult"
                           type="radio"
                           :id="'id_a_' + value.autoid + '_k_' + idx"
                           v-if="value.opflagname == '单选'"
                           v-model="value.select"
                           :value="itm"
                           @change="changeRadio(value)" />
                    <input :readonly="!!qresult"
                           type="checkbox"
                           :id="'id_a_' + value.autoid + '_k_' + idx"
                           v-model="value.select"
                           :value="itm"
                           v-else-if="value.opflagname == '多选'" />
                    <span>{{ itm }}</span>
                  </label>
                </div>
                <div style="color: red;font-size: 16px;"
                     class="pjmxl_top_list_check-fenshu"
                     v-if="value.opflagname == '单选'">
                  {{ value.score }}分
                </div>
              </div>
            </div>
            <div v-else-if="value.opflagname == '文本'"
                 class="pjmxl_top_listRight">
              <input :readonly="!!qresult"
                     type="text"
                     class="listRight_text"
                     v-model="value.select"
                     placeholder="请输入" />
            </div>
            <div v-else-if="value.opflagname == '日期'"
                 class="pjmxl_top_listRight">
              <van-field v-if="!!qresult"
                         readonly
                         name="datePicker"
                         :value="value.select" />
              <van-field v-else
                         readonly
                         clickable
                         name="datePicker"
                         :value="formatDate(value.select, 'yyyy-MM-dd')"
                         placeholder="点击选择时间"
                         @click="showDatePicker = true" />
              <van-popup v-model="showDatePicker"
                         position="bottom">
                <van-datetime-picker type="date"
                                     v-model="value.select"
                                     :min-date="minDate"
                                     :max-date="maxDate"
                                     :formatter="formatter"
                                     @confirm="onDateConfirm"
                                     @cancel="showDatePicker = false" />
              </van-popup>
            </div>
            <div v-else-if="value.opflagname == '日期时间'"
                 class="pjmxl_top_listRight">
              <van-field v-if="!!qresult"
                         readonly
                         name="datePicker"
                         :value="value.select" />
              <van-field v-else
                         readonly
                         clickable
                         name="datetimePicker"
                         :value="formatDate(value.select)"
                         placeholder="点击选择时间"
                         @click="showDateTimePicker = true" />
              <van-popup v-model="showDateTimePicker"
                         position="bottom">
                <van-datetime-picker type="datetime"
                                     v-model="value.select"
                                     :min-date="minDate"
                                     :max-date="maxDate"
                                     :formatter="formatter"
                                     @confirm="onDateTimeConfirm"
                                     @cancel="showDateTimePicker = false" />
              </van-popup>
            </div>
            <div v-else-if="value.opflagname == '整数'"
                 class="pjmxl_top_listRight">
              <van-stepper v-model="value.select"
                           :disabled="!!qresult"
                           min="1"
                           :integer="true"
                           step="1"
                           style="width: 100%" />
              <!-- <input :readonly="!!qresult"
                     type="text"
                     class="listRight_text"
                     v-model="value.select"> -->
            </div>
            <div v-else-if="value.opflagname == '浮点数'"
                 class="pjmxl_top_listRight">
              <input :readonly="!!qresult"
                     type="number"
                     class="listRight_text"
                     placeholder="请输入"
                     v-model="value.select"
                     @blur="blurIpt(key)" />
            </div>
            <div v-else-if="value.opflagname == '下拉列表'"
                 class="pjmxl_top_listRight">
              <van-field readonly
                         :disabled="!!qresult"
                         clickable
                         name="picker"
                         :value="value.select"
                         placeholder="请选择"
                         @click="getPickerData(value, key)" />
            </div>
          </div>

        </div>
      </div>
      <div class="mspjDaFenBox">
        <div class="mspjZdeFen">
          评价总得分：<strong style="font-weight: bold;font-size: 17px;">{{ totalScore }}</strong>
        </div>
      </div>
    </div>
    <van-collapse class="mspj-super-class"
                  v-model="activeNames"
                  v-if="is_master_iv && (fempname1 || fempname2 || fempname3 || fempname4 || fempname5) && activeItem == 3">
      <van-collapse-item v-if="fempname1"
                         name="1">
        <template #title>
          <span style="border: 1px solid #3284FF; border-radius: 4px;padding: 4px 5px;color: #3284ff;">副面试官1</span>
          <span style="color: #000;font-weight: bold;font-size: 15px;">【{{ fempname1 }}】<span v-if="f_iv_res1">【{{ f_iv_res1 }}】</span></span>
        </template>
        {{ f_pjmemo1?f_pjmemo1:'暂无评价' }}
      </van-collapse-item>
      <van-collapse-item v-if="fempname2"
                         name="2">
        <template #title>
          <span style="border: 1px solid #3284FF; border-radius: 4px;padding: 4px 5px;color: #3284ff;">副面试官2</span>
          <span style="color: #000;font-weight: bold;font-size: 15px;">【{{ fempname2 }}】<span v-if="f_iv_res2">【{{ f_iv_res2 }}】</span></span>
        </template>
        {{ f_pjmemo2?f_pjmemo2:'暂无评价' }}
      </van-collapse-item>
      <van-collapse-item v-if="fempname3"
                         name="3">
        <template #title>
          <span style="border: 1px solid #3284FF; border-radius: 4px;padding: 4px 5px;color: #3284ff;">副面试官3</span>
          <span style="color: #000;font-weight: bold;font-size: 15px;">【{{ fempname3 }}】<span v-if="f_iv_res3">【{{ f_iv_res3 }}】</span></span>
        </template>
        {{ f_pjmemo3?f_pjmemo3:'暂无评价' }}
      </van-collapse-item>
      <van-collapse-item v-if="fempname4"
                         name="4">
        <template #title>
          <span style="border: 1px solid #3284FF; border-radius: 4px;padding: 4px 5px;color: #3284ff;">副面试官4</span>
          <span style="color: #000;font-weight: bold;font-size: 15px;">【{{ fempname4 }}】<span v-if="f_iv_res4">【{{ f_iv_res4 }}】</span></span>
        </template>
        {{ f_pjmemo4?f_pjmemo4:'暂无评价' }}
      </van-collapse-item>
      <van-collapse-item v-if="fempname5"
                         name="5">
        <template #title>
          <span style="border: 1px solid #3284FF; border-radius: 4px;padding: 4px 5px;color: #3284ff;">副面试官5</span>
          <span style="color: #000;font-weight: bold;font-size: 15px;">【{{ fempname5 }}】<span v-if="f_iv_res5">【{{ f_iv_res5 }}】</span></span>
        </template>
        {{ f_pjmemo5?f_pjmemo5:'暂无评价' }}
      </van-collapse-item>
    </van-collapse>
    <div style="padding: 10px;
    margin: 10px 0;
    background: #fff;
    color: #ccc;
    font-size: 12px;"
         v-if="is_master_iv && activeItem == 3 && (!fempname1 && !fempname2 && !fempname3 && !fempname4 && !fempname5)">
      暂无副面试官评价
    </div>
    <van-cell class="mspj"
              v-if="is_master_iv"
              :class="result == '面试通过' ? 'requiredPj' : ''"
              :value="$t('viewEvaluate.EvaTxt1')" />
    <van-field v-if="is_master_iv"
               v-model="iv_pj"
               rows="5"
               class="remark"
               autosize
               type="textarea"
               :placeholder="ivpjPlaceholder"
               show-word-limit
               :disabled="!!qresult" />
    <van-cell class="mspj"
              v-if="!is_master_iv"
              required
              :value="$t('viewEvaluate.EvaTxt1')" />
    <van-field v-if="!is_master_iv"
               v-model="f_pjmemo"
               rows="5"
               class="remark"
               autosize
               type="textarea"
               :placeholder="ivpjPlaceholder"
               show-word-limit
               :disabled="!!(is_f_post && !is_master_iv)" />
    <van-cell v-if="is_master_iv"
              :value="$t('module.remark')" />
    <van-field v-if="is_master_iv"
               v-model="remark"
               rows="5"
               class="remark"
               autosize
               type="textarea"
               :placeholder="$t('module.PleaseEnter')"
               show-word-limit
               :disabled="!!qresult" />
    <div class="footer"
         v-if="!qresult && !(is_f_post && !is_master_iv)">
      <van-button type="info"
                  @click="saveIvSetView"
                  :disabled="isDisable">{{
        '提交'
      }}</van-button>
    </div>

    <!-- 普通下拉选的弹窗 -->
    <van-popup v-model="showPicker"
               position="bottom"
               round>
      <van-picker show-toolbar
                  :columns="columns"
                  @confirm="onPickerConfirm"
                  @cancel="showPicker = false">
      </van-picker>
    </van-popup>

    <!-- 查看评价项弹窗 -->
    <van-popup class="pjPopup"
               v-model="showPjPop"
               position="bottom"
               closeable
               round
               :style="{ height: '80%' }">
      <div class="pup_title">{{ $t('viewInfo.InfoTxt34') }}</div>
      <div class="pjmxl"
           v-if="pjdata2.length > 0">
        <div class="pjmxl_top">
          <div class="pjmxl_top_left">
            <span>{{ $t('viewEvaluate.EvaTxt3') }}</span>
          </div>
          <div class="pjmxl_top_right">
            <span>{{ $t('viewEvaluate.EvaTxt4') }}</span>
          </div>
        </div>
        <div class="pjmxl_body">
          <div class="pjmxl_top_list"
               v-for="(value, key) in pjdata2"
               :key="key">
            <!-- <div class="fzclick"
            v-if="!!qresult"></div>-->
            <div class="flexboxbox">
              <div class="pjmxl_top_listLeft">{{ value.optitle }}</div>
              <div v-if="value.opflagname == '单选' || value.opflagname == '多选'"
                   class="pjmxl_top_listRight">
                <label :for="'id_a_' + value.autoid + '_k_' + idx"
                       v-for="(itm, idx) in value.optarr"
                       :key="idx">
                  <input :disabled="true"
                         type="radio"
                         :id="'id_a_' + value.autoid + '_k_' + idx"
                         v-if="value.opflagname == '单选'"
                         v-model="value.select"
                         :value="itm" />
                  <input :disabled="true"
                         type="checkbox"
                         :id="'id_a_' + value.autoid + '_k_' + idx"
                         v-model="value.select"
                         :value="itm"
                         v-else-if="value.opflagname == '多选'" />
                  <span>{{ itm }}</span>
                </label>
              </div>
              <div v-else-if="value.opflagname == '文本'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datetimePicker"
                           :value="value.select" />
              </div>
              <div v-else-if="value.opflagname == '日期'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datePicker"
                           :value="value.select" />
              </div>
              <div v-else-if="value.opflagname == '日期时间'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datetimePicker"
                           :value="value.select" />
              </div>
              <div v-else-if="value.opflagname == '整数'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datetimePicker"
                           :value="value.select" />
              </div>
              <div v-else-if="value.opflagname == '浮点数'"
                   class="pjmxl_top_listRight">
                <van-field :readonly="true"
                           name="datetimePicker"
                           :value="value.select" />
              </div>
            </div>
            <div v-if="value.opflagname == '单选'"
                 style="position: absolute;
                      right: -15px;
                      top: 0px;
                      color: red;
                      font-size: 16px;
                      height: 100%;
                      display: flex;
                      align-items: center;">
              {{ value.score }}
            </div>
          </div>
        </div>
        <div style="text-align: left; font-size: 16px; color: red; margin-top: 10px;">
          评价总得分：{{ totalScore2 }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ivSetView, getPjDetail, postFpjMemo, getInterViewHisList, getInterViewInfo, getPjMemoText } from "@api/wxzp.js";

import { GetFilterDropDownList } from "@api/wx.js";
const userInfo = localStorage.userInfo ? JSON.parse(localStorage.userInfo) : {};
import { Toast } from "vant";
export default {
  data () {
    return {
      proposal: "", // 建议
      popType: "",
      showWorkType: false,
      showResult: false,
      showResult2: false,
      resultColumns: [],
      resultPjColumns: [],
      result2: '',
      result: '',
      qresult: '',
      remark: '',
      iv_pj: '',
      is_master_iv: '',
      is_f_post: '',
      f_pjmemo: '',
      f_pjmemo1: '',
      f_pjmemo2: '',
      f_pjmemo3: '',
      f_pjmemo4: '',
      f_pjmemo5: '',
      fempname1: '',
      fempname2: '',
      fempname3: '',
      fempname4: '',
      fempname5: '',
      f_iv_res1: '',
      f_iv_res2: '',
      f_iv_res3: '',
      f_iv_res4: '',
      f_iv_res5: '',
      iv_outreason: '',// 淘汰原因
      userInfo,
      leftToPath: "",
      isDisable: false,
      pjdata: [],
      radio: 1,
      showDatePicker: false,
      showDateTimePicker: false,
      minDate: new Date(),
      maxDate: new Date(2121, 1, 1),
      showPicker: false, // 下拉选弹窗显示
      columns: [], // 下拉选选项组
      pickerIdx: "", // 数组下标
      showReason: false, // 淘汰原因下拉
      ReasonColumns: [],
      totalScore: 0,
      totalScore2: 0,
      pjdata2: [],
      activeNames: ['1', '2', '3', '4', '5'],
      RsIV: [], // 面试记录
      activeNames1: ['5'],
      showPjPop: false, // 查看评价项弹窗
      activeItem: 1,
      f_can_show_iv_list: "true",
      ivpjPlaceholder: ""
    };
  },
  created () {
    this.getInterViewHisList();
    this.getFilrData();
    this.getResonFilrData();
    this.f_can_show_iv_list = (this.$route.query.f_can_show_iv_list == 'true')
    if (this.$route.query.pjid)
    {
      this.initData();
      this.getPjContent();
      this.getPjMemoTextData()
    }
  },
  methods: {
    getPjMemoTextData () {
      getPjMemoText({
        autoid: this.$route.query.autoid,
        username: this.userInfo.username
      }).then(res => {
        let text = res.data[0].info
        if (text)
        {
          this.ivpjPlaceholder = text.replace(/\\n/g, '\n')
        }
        let remark = res.data[0].remark
        if (remark && !this.f_pjmemo)
        {
          this.f_pjmemo = remark
        }
        if (remark && !this.iv_pj)
        {
          this.iv_pj = remark
        }
      })
    },
    initData () {
      getInterViewInfo({
        rs_autoid: this.$route.query.autoid,
        iv_autoid: this.$route.query.pjid,
        username: this.userInfo.username,
      }).then(res => {
        let obj = res.data[0];
        this.result2 = obj.f_iv_res;
        this.result = obj.iv_res;
        this.qresult = obj.iv_res;
        this.remark = obj.remark;
        this.iv_pj = obj.iv_pj;
        this.is_master_iv = obj.is_master_iv;
        this.is_f_post = obj.is_f_post;
        this.f_pjmemo = obj.f_pjmemo;
        this.f_pjmemo1 = obj.f_pjmemo1;
        this.f_pjmemo2 = obj.f_pjmemo2;
        this.f_pjmemo3 = obj.f_pjmemo3;
        this.f_pjmemo4 = obj.f_pjmemo4;
        this.f_pjmemo5 = obj.f_pjmemo5;
        this.fempname1 = obj.fempname1;
        this.fempname2 = obj.fempname2;
        this.fempname3 = obj.fempname3;
        this.fempname4 = obj.fempname4;
        this.fempname5 = obj.fempname5;
        this.f_iv_res1 = obj.f_iv_res1;
        this.f_iv_res2 = obj.f_iv_res2;
        this.f_iv_res3 = obj.f_iv_res3;
        this.f_iv_res4 = obj.f_iv_res4;
        this.f_iv_res5 = obj.f_iv_res5;
        this.iv_outreason = obj.iv_outreason;
        console.log(obj, 'dewedw')
      })
    },
    // 获取评价结果
    getPjJg () {

    },
    changeRadio (val) {
      this.totalScore = 0;
      if (this.pjdata.length > 0)
      {
        this.pjdata.forEach((ele, ix) => {
          if (ele.opflagname == "单选")
          {
            let ops = ele.optarr;
            if (ops.length > 0)
            {
              ops.forEach((it, idx) => {
                if (ele.select == it)
                {
                  this.totalScore += parseInt(ele.scorearr[idx]);
                }
              });
            }
          }
          if (val.autoid == ele.autoid)
          {
            let ops = ele.optarr;
            if (ops.length > 0)
            {
              ops.forEach((it, idx) => {
                if (ele.select == it)
                {
                  val.score = parseInt(ele.scorearr[idx]);
                  // this.$forceUpdate()
                }
              });
            }
          }
        });
      }
    },
    // 下拉选择器
    getPickerData (val, key) {
      this.pickerIdx = key;
      this.columns = val.optarr;
      this.showPicker = true;
    },
    // 下拉选择选择回调
    onPickerConfirm (value) {
      this.pjdata[this.pickerIdx].select = value;
      this.showPicker = false;
    },
    // 不够就补零
    add0 (m) {
      return m < 10 ? "0" + m : m;
    },
    // 日期时间转换格式
    formatDate (date, rule) {
      if (date)
      {
        const time = new Date(date);
        const y = time.getFullYear();
        const m = time.getMonth() + 1;
        const d = time.getDate();
        const h = time.getHours();
        const mm = time.getMinutes();
        const s = time.getSeconds();
        if (rule == "yyyy-MM-dd")
        {
          return y + "-" + this.add0(m) + "-" + this.add0(d);
        } else
        {
          return (
            y +
            "-" +
            this.add0(m) +
            "-" +
            this.add0(d) +
            " " +
            this.add0(h) +
            ":" +
            this.add0(mm) +
            ":" +
            this.add0(s)
          );
        }
      } else
      {
        return "";
      }
    },
    formatter (type, val) {
      if (type === "year")
      {
        return val + "年";
      }
      if (type === "month")
      {
        return val + "月";
      }
      if (type === "day")
      {
        return val + "日";
      }
      return val;
    },
    // 浮点数输入框校验
    blurIpt (index) {
      const val = this.pjdata[index]["select"];
      let reg = /^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/;
      if (val)
      {
        if (isNaN(val) || !reg.test(val))
        {
          Toast("请输入数字值，只允许保留两位小数");
          this.pjdata[index]["select"] = "";
        }
      }
    },
    // 日期选择回调
    onDateConfirm (value) {
      this.showDatePicker = false;
    },
    // 日期时间选择回调
    onDateTimeConfirm (value) {
      this.showDateTimePicker = false;
    },
    getInterViewHisList () {
      getInterViewHisList({
        autoid: this.$route.query.autoid,
        username: this.userInfo.username
      }).then(res => {
        this.RsIV = res.data;
      })
    },
    getFilrData () {
      GetFilterDropDownList({
        moduleno: 201,
        username: this.userInfo.username,
        listsql: "@面试结果",
      }).then((res) => {
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.resultColumns.push(res.data[i].dicvalue);
          }
        }
        // console.log(res)
      });
      GetFilterDropDownList({
        moduleno: 201,
        username: this.userInfo.username,
        listsql: "@副面试官评价结果",
      }).then((res) => {
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.resultPjColumns.push(res.data[i].dicvalue);
          }
          console.log(this.resultPjColumns, 'this.resultPjColumns')
        }
        // console.log(res)
      });
    },
    getResonFilrData () {
      GetFilterDropDownList({
        moduleno: 201,
        username: this.userInfo.username,
        listsql: "@面试淘汰原因",
      }).then((res) => {
        if (res.data.length > 0)
        {
          for (let i = 0; i < res.data.length; i++)
          {
            this.ReasonColumns.push(res.data[i].dicvalue);
          }
        }
        // console.log(res)
      });
    },
    getPjContent () {

      getPjDetail({
        pjid: this.$route.query.pjid,
      }).then((res) => {
        // console.log(res,'resresres');
        let pjarr = res.data;
        for (let i = 0; i < pjarr.length; i++)
        {
          pjarr[i].optitle = pjarr[i].optitle ? pjarr[i].optitle.replace(/\\n/g, "\n") : pjarr[i].optitle
          pjarr[i].optarr = pjarr[i].oplist.split(",");
          if (pjarr[i].opflagname != "多选")
          {
            if (pjarr[i].opres)
            {
              pjarr[i].select = pjarr[i].opres;
            } else
            {
              pjarr[i].select = "";
            }
          } else
          {
            if (pjarr[i].opres)
            {
              pjarr[i].select = pjarr[i].opres.split("|");
            } else
            {
              pjarr[i].select = [];
            }
          }
          if (pjarr[i].scorelist)
          {
            pjarr[i].scorearr = pjarr[i].scorelist.split(",");
          }
          if (pjarr[i].opflagname == "单选")
          {
            if (!pjarr[i].score)
            {
              pjarr[i].score = 0;
            }
            this.totalScore += pjarr[i].score;
          }
        }
        // console.log(pjarr);
        this.pjdata = pjarr;
        console.log(this.pjdata, "this.pjdata真的来了吗？");
      });
    },
    onClickLeft () {
      if (this.$route.query.returnPath == "viewInfo")
      {
        const ite = this.$route.query
        this.$router.push({
          path: "/" + this.$route.query.returnPath,
          query: {
            autoid: this.$route.query.autoid,
            footerType: "待入职",
            iv_res: this.iv_res,
            pjid: this.$route.query.pjid,
            iv_pj: this.iv_pj,
            remark: this.remark,
            is_master_iv: ite.is_master_iv,
            is_f_post: ite.is_f_post,

            f_pjmemo: ite.f_pjmemo,
            f_pjmemo1: ite.f_pjmemo1,
            f_pjmemo2: ite.f_pjmemo2,
            f_pjmemo3: ite.f_pjmemo3,
            f_pjmemo4: ite.f_pjmemo4,
            f_pjmemo5: ite.f_pjmemo5,
            fempname1: ite.fempname1,
            fempname2: ite.fempname2,
            fempname3: ite.fempname3,
            fempname4: ite.fempname4,
            fempname5: ite.fempname5,
            f_iv_res: this.result2,

            f_iv_res1: ite.f_iv_res1,
            f_iv_res2: ite.f_iv_res2,
            f_iv_res3: ite.f_iv_res3,
            f_iv_res4: ite.f_iv_res4,
            f_iv_res5: ite.f_iv_res5

          },
        });
      } else
      {
        this.$router.push({
          path: "/" + this.$route.query.returnPath,
        });
      }
    },
    showPopup (name) {
      this.showWorkType = true;
      this.popType = name;
    },
    onResultConfirm (value) {
      this.result = value;
      this.showResult = false;
    },
    onResultConfirm2 (value) {
      this.result2 = value;
      this.showResult2 = false;
    },
    showResultPopup () {
      this.showResult = true;
    },
    onResultCancel () {
      this.showResult = false;
    },
    // 面试记录--查看评价项
    showEvaItems (autoid) {
      this.showPjPop = true
      this.getPjContent2(autoid)
    },
    // 查看面试评价详情
    getPjContent2 (autoid) {
      this.totalScore2 = 0
      getPjDetail({
        pjid: autoid
      }).then(res => {
        // console.log(res,'resresres');
        let pjarr = res.data
        for (let i = 0; i < pjarr.length; i++)
        {
          pjarr[i].optitle = pjarr[i].optitle ? pjarr[i].optitle.replace(/\\n/g, "\n") : pjarr[i].optitle
          pjarr[i].optarr = pjarr[i].oplist.split(',')
          if (pjarr[i].opflagname != '多选')
          {
            if (pjarr[i].opres)
            {
              pjarr[i].select = pjarr[i].opres
            } else
            {
              pjarr[i].select = ''
            }
          } else
          {
            if (pjarr[i].opres)
            {
              pjarr[i].select = pjarr[i].opres.split('|')
            } else
            {
              pjarr[i].select = []
            }
          }
          if (pjarr[i].opflagname == '单选')
          {
            if (!pjarr[i].score)
            {
              pjarr[i].score = 0
            }
            this.totalScore2 += pjarr[i].score
          }
        }
        // console.log(pjarr);
        this.pjdata2 = pjarr
      })
    },
    onConfirm (value, index) {
      this.showWorkType = false;
      if (this.popType == "评分")
      {
        this.score = value;
      } else if (this.popType == "建议")
      {
        this.proposal = value;
      }
    },
    onCancel () {
      this.showWorkType = false;
    },
    saveIvSetView () {
      let pjdetail = "";
      if (this.is_master_iv)
      {
        if (!this.result)
        {
          Toast(this.$t("viewEvaluate.EvaTxt5"));
          return false;
        }
        if (this.result == "面试通过" && !this.iv_pj)
        {
          Toast(this.$t("viewEvaluate.EvaTxt6"));
          return false;
        }
        if (this.result == "面试淘汰" && !this.iv_outreason)
        {
          Toast("淘汰原因不能为空！");
          return false;
        }

        if (this.pjdata.length > 0)
        {
          for (let i = 0; i < this.pjdata.length; i++)
          {
            if (this.pjdata[i].opflagname != "多选")
            {
              // if (this.pjdata[i].select == "" && this.result != '面试淘汰')
              if (
                this.pjdata[i].select == "" &&
                ((this.result == "面试通过" && this.pjdata[i].tg_bt) ||
                  (this.result == "面试淘汰" && this.pjdata[i].tt_bt) ||
                  (this.result == "待定" && this.pjdata[i].dd_bt))
              )
              {
                Toast(
                  this.$t("viewEvaluate.EvaTxt7") +
                  "--" +
                  this.pjdata[i].optitle
                );
                return false;
              } else
              {
                if (this.pjdata[i].opflagname == "日期")
                {
                  this.pjdata[i].select = this.formatDate(
                    this.pjdata[i].select,
                    "yyyy-MM-dd"
                  );
                } else if (this.pjdata[i].opflagname == "日期时间")
                {
                  this.pjdata[i].select = this.formatDate(
                    this.pjdata[i].select
                  );
                }
                if (i == this.pjdata.length - 1)
                {
                  if (this.pjdata[i].opflagname == "单选")
                  {
                    pjdetail +=
                      this.pjdata[i].autoid +
                      ":" +
                      this.pjdata[i].select +
                      "{" +
                      this.pjdata[i].score +
                      "}";
                  } else
                  {
                    pjdetail +=
                      this.pjdata[i].autoid + ":" + this.pjdata[i].select;
                  }
                } else
                {
                  if (this.pjdata[i].opflagname == "单选")
                  {
                    pjdetail +=
                      this.pjdata[i].autoid +
                      ":" +
                      this.pjdata[i].select +
                      "{" +
                      this.pjdata[i].score +
                      "}" +
                      ",";
                  } else
                  {
                    pjdetail +=
                      this.pjdata[i].autoid + ":" + this.pjdata[i].select + ",";
                  }
                }
              }
            } else
            {
              // if (this.pjdata[i].select.length == 0 && this.result != '面试淘汰')
              if (
                this.pjdata[i].select.length == 0 &&
                ((this.result == "面试通过" && this.pjdata[i].tg_bt) ||
                  (this.result == "面试淘汰" && this.pjdata[i].tt_bt) ||
                  (this.result == "待定" && this.pjdata[i].dd_bt))
              )
              {
                Toast(
                  this.$t("viewEvaluate.EvaTxt7") +
                  "--" +
                  this.pjdata[i].optitle
                );
                return false;
              } else
              {
                if (i == this.pjdata.length - 1)
                {
                  pjdetail +=
                    this.pjdata[i].autoid +
                    ":" +
                    this.pjdata[i].select.join("|");
                } else
                {
                  pjdetail +=
                    this.pjdata[i].autoid +
                    ":" +
                    this.pjdata[i].select.join("|") +
                    ",";
                }
              }
            }
          }
        }

        ivSetView({
          autoid: this.$route.query.autoid,
          iv_res: this.result,
          iv_pj: this.iv_pj,
          iv_outreason: this.iv_outreason,
          remark: this.remark,
          username: this.userInfo.username,
          pjdetail,
        }).then((res) => {
          if (res.data[0].info == "")
          {
            Toast(this.$t("viewEvaluate.EvaTxt8"));
            this.isDisable = true;
            this.$router.push("/toInterview");
          } else
          {
            Toast(res.data[0].info);
          }
        });
      } else
      {
        if (!this.f_pjmemo)
        {
          Toast('面试评价不能为空');
          return false;
        }
        if (!this.result2)
        {
          Toast("评价结果不能为空！");
          return false;
        }
        postFpjMemo({
          iv_autoid: this.$route.query.pjid,
          f_pjmemo: this.f_pjmemo,
          empid: this.userInfo.empid,
          f_iv_res: this.result2
        }).then((res) => {
          if (res.data[0].info == "")
          {
            Toast(this.$t("viewEvaluate.EvaTxt8"));
            this.isDisable = true;
            this.$router.push("/toInterview");
          } else
          {
            Toast(res.data[0].info);
          }
        });
      }

      // console.log(pjdetail)

    },
    onReasonConfirm (value) {
      this.iv_outreason = value;
      this.showReason = false;
    },
  },
};
</script>

<style lang="less" scoped>
.evaluate {
  // height: 100vh;
  text-align: center;
  .van-nav-bar {
    background: #2b8df0;
    height: 92px;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    ::v-deep .van-nav-bar__right {
      span {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    .van-icon-ellipsis {
      color: #fff;
    }
  }
  .van-field {
    margin-bottom: 28px;
    box-sizing: border-box;
  }
  /deep/.van-cell__value {
    .van-field__body {
      input {
        text-align: right;
      }
    }
  }
  .mspj {
    // padding-left: 18px;
    box-sizing: border-box;
  }
  .requiredPj {
    span::before {
      content: "*";
      color: red;
      margin-right: 4px;
    }
  }
  /deep/.gzxz::after {
    border-bottom: 0 !important;
  }
  .footer {
    padding: 30px 0;
    background: #fff;
    .remark {
      margin-bottom: 0;
      height: calc(100% - 180px);
      box-sizing: border-box;
    }
    .van-button {
      width: 90%;
    }
  }
  .pjmxl {
    width: 100%;
    background: #fff;
    // padding: 0.26667rem 0.42667rem;
    padding: 24px;
    margin-bottom: 0.37333rem;
    .pjmxl_top {
      width: 100%;
      display: flex;
      height: 80px;
      align-items: center;
      border-top: 1px solid #c0c0c0;
      border-left: 1px solid #c0c0c0;
      border-right: 1px solid #c0c0c0;
      background: #eaecf3;
      .pjmxl_top_left {
        width: 30%;
        font-size: 28px;
        height: 100%;
        border-right: 1px solid #c0c0c0;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
      .pjmxl_top_right {
        width: 70%;
        height: 100%;
        font-size: 28px;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
    }
    .pjmxl_body {
      width: 100%;
      .fzclick {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 999999;
        top: 0;
      }
      .pjmxl_top_list {
        width: 100%;

        // height: 80px;
        position: relative;
        align-items: center;
        border-left: 1px solid #c0c0c0;
        border-right: 1px solid #c0c0c0;
        border-bottom: 1px solid #c0c0c0;
        input:disabled {
          background: blue;
        }
      }
      .flexboxbox {
        display: flex;
        width: 100%;
        height: 100%;
      }
      .pjmxl_top_listLeft {
        width: 30%;
        font-size: 24px;
        // height: 100%;
        min-height: 80px;
        border-right: 1px solid #c0c0c0;
        align-items: center;
        display: flex;
        padding: 15px;
        text-align: justify;
        // justify-content: center;
        // text-align: center;
        white-space: pre-wrap;
      }
      .pjmxl_top_must:before {
        content: "*";
        color: red;
        margin-right: 4px;
        // position: absolute;
        // left: 0.21333rem;
        // color: #ee0a24;
        // font-size: 0.37333rem;
        // content: "*";
      }
      .pjmxl_top_list_check-center {
        width: 100%;
        padding: 0 10px;
        // min-height: 145px;
        display: flex;
        align-items: center;
      }
      .pjmxl_top_list_check {
        flex-wrap: wrap;
        justify-content: flex-start;
        position: relative !important;
      }
      .pjmxl_top_list_check-top {
        display: flex;
        width: 86%;
        flex-wrap: wrap;
        height: 100px;
        align-items: flex-start;
        padding-top: 0.1rem;
      }
      .pjmxl_top_list_check-fenshu {
        flex: 1;
        text-align: center;
        // width: 100%;
        // text-align: right !important;
        // position: absolute;
        // right: 15px !important;
        // bottom: 15px !important;
      }
      .pjmxl_top_listRight {
        width: 70%;
        display: flex;
        // justify-content: space-around;
        align-items: center;
        label {
          font-size: 24px;
          display: flex;
          align-items: center;
          margin: 0 6px;
        }
        .listRight_text {
          font-size: 24px;
          height: 95%;
          width: 98%;
          border: 0;
          outline: 0;
        }
        .van-cell {
          margin-bottom: 0;
          padding: 0;
          font-size: 24px;
          height: 95%;
          width: 98%;
          border: 0 !important;
          /deep/.van-cell__value {
            .van-field__body {
              height: 100%;
              .van-field__control {
                text-align: left;
              }
            }
          }
        }
        /deep/.van-stepper {
          .van-stepper__input {
            width: 60%;
          }
        }
      }
    }
  }
}
.mspjDaFenBox {
  width: 100%;
  height: 100px;
  background: url("~@/assets/img/mspjDaFen.png") no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: right;
  padding-right: 20px;
}
.mspjZdeFen {
  height: 70px;
  background: #fff;
  border-radius: 70px;
  width: 300px;
  text-align: center;
  line-height: 70px;
  font-size: 32px;
}

.msjlfshjdkhf {
  /deep/.van-cell__title {
    text-align: left !important;
  }
  /deep/.van-collapse-item__content {
    background: none !important;
    padding: 0 !important;
  }
}
.ivRecord {
  opacity: 1;
  padding: 0.32rem 0.42667rem !important;
  background: #fff !important;
  margin-bottom: 0.26667rem;
  padding-bottom: 10px;
  // border-bottom: 1px solid #f5f6f7;
  box-shadow: none;
  .record_child {
    // padding: 20px;
    .van-row {
      margin-bottom: 40px;
      font-size: 32px;
      .van-col--8 {
        text-align: left !important;
      }
      .van-col--15 {
        color: #333;
        text-align: right !important;
      }
    }
    .show_pj {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      .van-button {
        font-size: 28px;
      }
    }
  }
}
.pjPopup {
  overflow: hidden;
  .pup_title {
    padding: 28px;
    text-align: center;
    font-size: 0.48rem;
    font-family: Source Han Sans CN;
    font-weight: 500;
  }
  .pjmxl {
    height: calc(100% - 96px);
    overflow: auto;
    width: 100%;
    background: #fff;
    // padding: 0.26667rem 0.42667rem;
    padding: 20px 50px 20px 40px;
    margin-bottom: 0.37333rem;
    .pjmxl_top {
      width: 100%;
      display: flex;
      height: 80px;
      align-items: center;
      border-top: 1px solid #666;
      border-left: 1px solid #666;
      border-right: 1px solid #666;
      border-bottom: 1px solid #666;
      .pjmxl_top_left {
        width: 30%;
        font-size: 28px;
        height: 100%;
        border-right: 1px solid #666;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
      .pjmxl_top_right {
        width: 70%;
        height: 100%;
        font-size: 28px;
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
    }
    .pjmxl_body {
      width: 100%;
      .fzclick {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        z-index: 999999;
        top: 0;
      }
      .pjmxl_top_list {
        width: 100%;

        // height: 80px;
        position: relative;
        align-items: center;
        border-left: 1px solid #666;
        border-right: 1px solid #666;
        border-bottom: 1px solid #666;
        input:disabled {
          background: blue;
        }
      }
      .flexboxbox {
        display: flex;
        width: 100%;
        height: 100%;
      }
      .pjmxl_top_listLeft {
        width: 30%;
        font-size: 24px;
        height: 100%;
        border-right: 1px solid #666;
        align-items: center;
        display: flex;
        // justify-content: center;
        text-align: justify !important;
      }
      .pjmxl_top_listRight {
        width: 70%;
        display: flex;
        // justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        label {
          font-size: 24px;
          display: flex;
          align-items: center;
        }
        .listRight_text {
          font-size: 24px;
          height: 95%;
          width: 98%;
        }
        .van-cell {
          margin-bottom: 0;
          padding: 0;
          font-size: 24px;
          height: 95%;
          width: 98%;
          border: 1px solid #666;
          /deep/.van-cell__value {
            .van-field__body {
              height: 100%;
              .van-field__control {
                text-align: left;
                padding-left: 16px;
              }
            }
          }
        }
        /deep/.van-stepper {
          .van-stepper__input {
            width: 60%;
          }
        }
      }
    }
  }
}

.pjzfsBox {
  width: 100%;
  display: flex;
  background: #fff;
  margin-bottom: 12px;
  padding: 12px;
  padding-right: 20px;
  .left_pjzfsBox {
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    border-right: 1px solid #eee;
  }
  .right_pjzfsBox {
    display: flex;
    flex: 3;
    .flex1 {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      max-width: 33.33333% !important;
    }
    .flex1Item {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
        font-size: 14px;
        margin-top: 10px;
      }
      &.activeItemCls {
        p {
          color: #2b8df0;
        }
      }
    }
  }
}
</style>
